@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;900&display=swap");
* {
  font-family: "Poppins", sans-serif;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .shema__borders {
    @apply relative after:content-[''] after:absolute after:bg-[#74AF4D] after:h-3 after:w-3 after:rounded-full;
  }
}

.apexcharts-tooltip-title:empty {
  display: none;
}

.MuiPagination-root .Mui-selected {
  background: #74af4d !important;
  color: "#fff";
}

area {
  cursor: pointer;
}

.rdrMonth {
  flex: 1;
}

.corner__borders {
  background: linear-gradient(to right, white 1px, transparent 1px) 0 0,
    linear-gradient(to right, white 1px, transparent 1px) 0 100%,
    linear-gradient(to left, white 1px, transparent 1px) 100% 0,
    linear-gradient(to left, white 1px, transparent 1px) 100% 100%,
    linear-gradient(to bottom, white 1px, transparent 1px) 0 0,
    linear-gradient(to bottom, white 1px, transparent 1px) 100% 0,
    linear-gradient(to top, white 1px, transparent 1px) 0 100%,
    linear-gradient(to top, white 1px, transparent 1px) 100% 100%;

  background-repeat: no-repeat;
  background-size: 20px 20px;
  padding: 8px;
}

.corner__borders__2 {
  background: linear-gradient(to right, #74af4d 2px, transparent 2px) 0% -380%,
    linear-gradient(to right, #74af4d 2px, transparent 2px) 0% 480%,
    linear-gradient(to left, #74af4d 2px, transparent 2px) 100% -380%,
    linear-gradient(to left, #74af4d 2px, transparent 2px) 100% 480%,
    linear-gradient(to bottom, #74af4d 2px, transparent 2px) -30% 0%,
    linear-gradient(to bottom, #74af4d 2px, transparent 2px) 130% 0%,
    linear-gradient(to top, #74af4d 2px, transparent 2px) -30% 100%,
    linear-gradient(to top, #74af4d 2px, transparent 2px) 130% 100%;

  background-repeat: no-repeat;
  background-size: 20px 20px;
  /* padding: 8px; */
}

.calendarWrap {
  display: inline-block;
  position: relative;
}

.calendarElement {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 40px;
  border: 1px solid #ccc;
  z-index: 999;
}

#polygon_id:hover polyline {
  stroke: green;
  fill: green;
}

/* .fillable {
  fill: transparent !important;
  pointer-events: all;
}

.fillable:hover {
  fill: blue !important;
} */

/* #image-container {
  -moz-transform: scale(0.2);
  -moz-transform-origin: 0 0;
} */
